import { useState, useEffect } from "react";

import { useMap } from "react-map-gl";

import { useQuery } from "@tanstack/react-query";

import { z } from "zod";

import { CoordinatesSchema } from "@/types";

const MAPBOX_GEOCODING_API_URL =
  "https://api.mapbox.com/search/geocode/v6/forward";

const GeocodingResultSchema = z.object({
  type: z.literal("FeatureCollection"),
  features: z.array(
    z.object({
      type: z.literal("Feature"),
      properties: z.object({
        name: z.string(),
        place_formatted: z.string(),
        full_address: z.string(),
        coordinates: CoordinatesSchema,
      }),
    }),
  ),
});

export function useMapGeocoding(
  mapboxAccessToken: string,
  searchValue: string,
  autoCompleteOpen: boolean,
  limit: number = 4,
) {
  const [proximity, setProximity] = useState<GeoJSON.Position | null>(null);
  const { current: map } = useMap();

  useEffect(() => {
    map?.on("moveend", () => {
      if (map?.getZoom() > 9) {
        const center = map.getCenter().wrap();
        setProximity([center.lng, center.lat]);
      } else {
        setProximity(null);
      }
    });
  }, [map]);

  return useQuery({
    queryKey: ["geocoding", searchValue, proximity],
    staleTime: Infinity,
    queryFn: async () => {
      const params = new URLSearchParams({
        access_token: mapboxAccessToken,
        q: searchValue,
        country: "NL",
        language: "nl",
        limit: limit.toString(),
        ...(proximity && { proximity: `${proximity[0]},${proximity[1]}` }),
      });

      const response = await fetch(
        `${MAPBOX_GEOCODING_API_URL}?${params.toString()}`,
      );

      return GeocodingResultSchema.parse(await response.json());
    },
    select: (data) =>
      data.features.map((feature) => ({
        title: feature.properties.name,
        subtitle: feature.properties.place_formatted,
        value: feature.properties.full_address,
        coordinates: feature.properties.coordinates,
      })),
    enabled: autoCompleteOpen && !!searchValue,
  });
}
