import { useCallback, useMemo } from "react";

import { ChevronDown, LoaderCircle, X } from "lucide-react";

import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

import PinIcon from "@/components/icons/pin";
import FlagIcon from "@/components/icons/flag";

import { cn } from "@/lib/utils";
import { formatDistance, formatDuration, formatPrice } from "@/lib/formatters";
import { trackEvent } from "@/lib/analytics";

import { useAppState } from "@/state";
import type { Location } from "@/types";
import { SEARCH_DISTANCES_KM } from "@/consts";

import { useStationsBetween } from "@/hooks/use-stations-between";

type RouteDetailsProps = {
  departureLocation: Location | null;
  destinationLocation: Location | null;
  className?: string;
};

function RouteDetails({
  departureLocation,
  destinationLocation,
  className,
}: RouteDetailsProps) {
  const {
    searchDistance,
    routeId,
    setSearchDistance,
    setRouteId,
    setSelectedStation,
    setDepartureLocation,
    setDestinationLocation,
  } = useAppState();
  const { stationsAlongRoute, allRoutes } = useStationsBetween(
    departureLocation,
    destinationLocation,
  );

  const sortedStations = useMemo(
    () =>
      (stationsAlongRoute || []).sort(
        (a, b) =>
          // Sort by price, then by distance
          (a.station.price?.euro95 ?? Infinity) -
            (b.station.price?.euro95 ?? Infinity) ||
          a.distanceFromDeparture - b.distanceFromDeparture,
      ),
    [stationsAlongRoute],
  );

  const clearRoute = useCallback(() => {
    setDepartureLocation(null, { history: "replace" });
    setDestinationLocation(null, { history: "replace" });
  }, [setDepartureLocation, setDestinationLocation]);

  return (
    <div className={cn("flex flex-col", className)}>
      <div className="flex items-end justify-between">
        <div className="text-lg font-semibold tracking-tight">Tankstations</div>
        <Button
          variant="ghost"
          size="icon"
          className="relative -right-2 md:right-0 [&_svg]:size-6"
          onClick={clearRoute}
        >
          <X className="text-muted-foreground" />
        </Button>
      </div>
      <div className="mt-2 text-muted-foreground">
        Binnen{" "}
        <DropdownMenu>
          <DropdownMenuTrigger className="inline-flex items-center gap-1 rounded-lg border bg-secondary px-1 text-secondary-foreground">
            {searchDistance} km <ChevronDown className="size-4" />
          </DropdownMenuTrigger>
          <DropdownMenuContent align="start">
            <DropdownMenuLabel>Zoekafstand</DropdownMenuLabel>
            <DropdownMenuSeparator />
            {SEARCH_DISTANCES_KM.map((distance) => (
              <DropdownMenuCheckboxItem
                key={distance}
                checked={distance === searchDistance}
                onClick={() => setSearchDistance(distance)}
              >
                {distance} km
              </DropdownMenuCheckboxItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>{" "}
        langs{" "}
        <DropdownMenu>
          <DropdownMenuTrigger className="inline-flex items-center gap-1 rounded-lg border bg-secondary px-1 text-secondary-foreground">
            route {routeId + 1} <ChevronDown className="size-4" />
          </DropdownMenuTrigger>
          <DropdownMenuContent align="start">
            <DropdownMenuLabel>Routes</DropdownMenuLabel>
            <DropdownMenuSeparator />
            {allRoutes?.map((route, i) => (
              <DropdownMenuCheckboxItem
                key={i}
                checked={i === routeId}
                onClick={() => setRouteId(i)}
              >
                {route.properties.summary} (
                {formatDuration(route.properties.duration_typical)},{" "}
                {formatDistance(route.properties.distance, "m")})
              </DropdownMenuCheckboxItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
        <div className="mt-1 flex items-center gap-2">
          <PinIcon className="size-4" /> van {departureLocation?.name}
        </div>
        <div className="flex items-center gap-2">
          <FlagIcon className="size-4" /> naar {destinationLocation?.name}
        </div>
      </div>
      <div className="mt-4 flex flex-col gap-2">
        {!stationsAlongRoute ? (
          <div className="flex items-center justify-center p-8">
            <LoaderCircle className="h-6 w-6 animate-spin" />
          </div>
        ) : stationsAlongRoute.length > 0 ? (
          sortedStations.map(({ station, distanceFromDeparture }, i) => (
            <Button
              key={station.id}
              variant="ghost"
              size="lg"
              className="pl-2 pr-4"
              onClick={() => {
                trackEvent("select_station", {
                  station_id: station.id,
                  station_name: station.name,
                });
                setSelectedStation(station);
              }}
            >
              <div className="flex w-full items-center gap-2">
                <div className="min-w-[1.4rem] place-self-start text-right text-muted-foreground">
                  {i + 1}.
                </div>
                <div className="flex w-full flex-wrap justify-start leading-tight">
                  <span className="mr-2">{station.name}</span>
                  <span className="text-muted-foreground">
                    na {formatDistance(distanceFromDeparture)}
                  </span>
                </div>
                {station.price?.euro95 && (
                  <div
                    className={`text-sm ${
                      station.price.euro95_below_average
                        ? "text-emerald-500"
                        : "text-rose-500"
                    }`}
                  >
                    {formatPrice(station.price.euro95 ?? 0, true)}
                  </div>
                )}
              </div>
            </Button>
          ))
        ) : (
          <div className="mt-4">
            Geen tankstations gevonden langs deze route.
          </div>
        )}
      </div>
    </div>
  );
}

export default RouteDetails;
