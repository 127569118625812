"use client";

import { useMemo } from "react";

import { ExternalLink, CalendarDays, X, BadgeEuro } from "lucide-react";

import { Bar, BarChart, CartesianGrid, XAxis, YAxis } from "recharts";

import { CardDescription, CardTitle } from "@/components/ui/card";
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart";
import {
  PriceCard,
  PriceCardTitle,
  PriceCardValue,
  PriceCardContent,
  PriceCardBadge,
} from "@/components/price-card";
import { Skeleton } from "@/components/ui/skeleton";
import { Button } from "@/components/ui/button";

import {
  formatChange,
  formatDate,
  formatDateTime,
  formatPrice,
  formatTime,
} from "@/lib/formatters";

import { useSelectedStationDetails, useStationsAveragePrice } from "@/api";
import { useAppState } from "@/state";

import type { GasPrices, Station } from "@/types";
import { FUEL_TANK_CAPACITY_L } from "@/consts";

const chartConfig = {
  euro95: {
    label: "Euro95",
  },
} satisfies ChartConfig;

// Current Price Component
interface CurrentPriceProps {
  price?: number | null;
  averagePrice?: number | null;
}

function CurrentPrice({ price, averagePrice }: CurrentPriceProps) {
  const changePercentage =
    price && averagePrice
      ? formatChange(((price - averagePrice) / averagePrice) * 100)
      : null;

  return (
    <PriceCard className="mt-8 p-6">
      <PriceCardTitle className="text-base font-medium">Actueel</PriceCardTitle>
      <PriceCardContent>
        {!price || !averagePrice ? (
          <Skeleton className="h-12 w-24" />
        ) : (
          <>
            <PriceCardValue className="text-5xl">
              {formatPrice(price)}
            </PriceCardValue>
            {changePercentage !== "0.0" && (
              <PriceCardBadge
                className={`text-lg font-semibold ${
                  changePercentage?.startsWith("-")
                    ? "text-emerald-500"
                    : "text-rose-500"
                }`}
              >
                {changePercentage}%
              </PriceCardBadge>
            )}
          </>
        )}
      </PriceCardContent>
    </PriceCard>
  );
}

function cheapestDayPerWeek(monthPrices: GasPrices): string {
  if (!monthPrices.length) {
    return "";
  }

  const dayAverages = monthPrices.reduce<
    Record<string, { sum: number; count: number }>
  >((acc, price) => {
    const day = new Date(price.timestamp).toLocaleDateString("nl-NL", {
      weekday: "long",
    });
    if (!acc[day]) {
      acc[day] = { sum: 0, count: 0 };
    }
    acc[day].sum += price.euro95 ?? 0;
    acc[day].count += 1;
    return acc;
  }, {});

  const cheapestDay = Object.entries(dayAverages)
    .map(([day, { sum, count }]) => ({
      day,
      average: sum / count,
    }))
    .reduce((min, curr) => (curr.average < min.average ? curr : min));

  return cheapestDay.day;
}

// Add this new component
interface InsightCardProps {
  IconComponent: React.ElementType;
  children: React.ReactNode;
}

function InsightCard({ IconComponent, children }: InsightCardProps) {
  return (
    <PriceCard className="mt-4 flex flex-row items-baseline gap-2 p-4 text-base tracking-tight">
      <IconComponent className="relative top-[0.2em] size-4 shrink-0" />
      <span>{children}</span>
    </PriceCard>
  );
}

// Price Statistics Component
interface PriceInsightsProps {
  currentPrice: number;
  dayPrices: GasPrices;
  monthPrices: GasPrices;
  averagePrice: number;
}

function PriceInsights({
  dayPrices,
  monthPrices,
  currentPrice,
  averagePrice,
}: PriceInsightsProps) {
  const euro95Prices = dayPrices.map((price) => price.euro95 ?? 0);
  const minPrice = Math.min(...euro95Prices);
  const maxPrice = Math.max(...euro95Prices);
  const savings = (averagePrice - currentPrice) * FUEL_TANK_CAPACITY_L;
  const cheapestDay = cheapestDayPerWeek(monthPrices);

  const stats = [
    {
      title: "Min vandaag",
      value: minPrice,
      change: formatChange(((minPrice - currentPrice) / currentPrice) * 100),
      badgeColor: "text-emerald-500",
    },
    {
      title: "Max vandaag",
      value: maxPrice,
      change: formatChange(((maxPrice - currentPrice) / currentPrice) * 100),
      badgeColor: "text-rose-500",
    },
  ];

  return (
    <div className="mt-8">
      <CardTitle>Prijsinzicht</CardTitle>
      <InsightCard IconComponent={BadgeEuro}>
        Besparing bij een volle tank is{" "}
        <span
          className={`font-semibold ${savings > 0 ? "text-emerald-500" : "text-rose-500"}`}
        >
          {formatPrice(savings, true)}
        </span>
        <div className="text-xs text-muted-foreground">
          {FUEL_TANK_CAPACITY_L}L tankinhoud t.o.v. landelijk gemiddelde
        </div>
      </InsightCard>
      <InsightCard IconComponent={CalendarDays}>
        Beste dag om te tanken is{" "}
        <span className="font-semibold">{cheapestDay}</span>
        <div className="text-xs text-muted-foreground">
          o.b.v. 30-dagen gem. dagprijs
        </div>
      </InsightCard>
      <div className="mt-4 grid grid-cols-2 gap-2">
        {stats.map(({ title, value, change, badgeColor }) => (
          <PriceCard className="px-4 py-3" key={title}>
            <PriceCardTitle>{title}</PriceCardTitle>
            <PriceCardContent>
              {!euro95Prices.length || !currentPrice ? (
                <Skeleton className="h-8 w-14" />
              ) : (
                <>
                  <PriceCardValue>{formatPrice(value)}</PriceCardValue>
                  {change !== "0.0" && (
                    <PriceCardBadge className={`text-sm ${badgeColor}`}>
                      {change}%
                    </PriceCardBadge>
                  )}
                </>
              )}
            </PriceCardContent>
          </PriceCard>
        ))}
      </div>
    </div>
  );
}

// Price Chart Component
interface PriceChartProps {
  title: string;
  data: GasPrices;
  period: "24h" | "30d";
}

function PriceChart({ title, data, period }: PriceChartProps) {
  const chartData = useMemo(() => {
    // Reverse the array to sort them in ascending order
    return data.toReversed();
  }, [data]);

  return (
    <div className="mt-8">
      <CardTitle>{title}</CardTitle>
      <CardDescription>
        In de afgelopen {period === "24h" ? "24 uur" : "30 dagen"}
      </CardDescription>
      {!data.length ? (
        <Skeleton className="mt-4 h-[200px] w-full" />
      ) : (
        <ChartContainer
          config={chartConfig}
          className="mt-4 min-h-[200px] w-full"
        >
          <BarChart
            accessibilityLayer
            data={chartData}
            margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
          >
            <CartesianGrid vertical={false} />
            <YAxis domain={["dataMin - 0.025", "dataMax"]} hide />
            <XAxis
              dataKey="timestamp"
              axisLine={false}
              tickLine={false}
              tickMargin={4}
              interval="preserveStartEnd"
              tickFormatter={(value) =>
                period === "24h" ? formatTime(value) : formatDate(value)
              }
            />
            <ChartTooltip
              cursor={false}
              content={
                <ChartTooltipContent
                  hideIndicator
                  labelFormatter={(_, [payload]) =>
                    period === "24h"
                      ? formatDateTime(payload.payload.timestamp)
                      : formatDate(payload.payload.timestamp, {
                          includeDayName: true,
                        })
                  }
                />
              }
            />
            <Bar
              dataKey="euro95"
              fill="hsl(var(--primary))"
              activeBar={{ fill: "hsl(var(--muted-foreground))" }}
              radius={2}
            />
          </BarChart>
        </ChartContainer>
      )}
    </div>
  );
}

interface StationDetailsProps {
  className?: string;
  station: Station;
}

function StationDetails({ className, station }: StationDetailsProps) {
  const { setSelectedStation } = useAppState();

  const { data: averagePrice } = useStationsAveragePrice();
  const { data: stationDetails } = useSelectedStationDetails();

  const dayPrices = stationDetails?.prices_24_hours ?? [];
  const monthPrices = stationDetails?.prices_30_days ?? [];

  const currentPrice = dayPrices.length > 0 ? dayPrices[0].euro95 : undefined;
  const fullAddress = `${stationDetails?.address}, ${stationDetails?.postal_code} ${stationDetails?.city}`;

  return (
    <div className={className}>
      <div className="flex items-end justify-between">
        <h1 className="scroll-m-20 text-2xl font-semibold tracking-tight">
          {station.name}
        </h1>{" "}
        <Button
          variant="ghost"
          size="icon"
          className="relative -right-2 md:right-0 [&_svg]:size-6"
          onClick={() => setSelectedStation(null, { history: "replace" })}
        >
          <X className="text-muted-foreground" />
        </Button>
      </div>

      {stationDetails ? (
        <a
          href={`https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
            fullAddress,
          )}`}
          target="_blank"
          className="inline-flex items-center gap-2 leading-7 underline-offset-4 hover:underline"
          title="Navigeren naar locatie"
        >
          {fullAddress} <ExternalLink className="size-4" />
        </a>
      ) : (
        <Skeleton className="h-7 w-2/3" />
      )}

      <div className="mt-8">
        <span className="rounded-lg bg-white px-3 py-2 text-base font-semibold text-emerald-700">
          Euro95
        </span>
      </div>

      <CurrentPrice price={currentPrice} averagePrice={averagePrice?.euro95} />
      <PriceInsights
        averagePrice={averagePrice?.euro95 ?? 0}
        dayPrices={dayPrices}
        monthPrices={monthPrices}
        currentPrice={currentPrice ?? 0}
      />
      <PriceChart data={dayPrices} title="Uurprijs" period="24h" />
      <PriceChart data={monthPrices} title="Gemiddelde dagprijs" period="30d" />
    </div>
  );
}

export default StationDetails;
