"use client";

import "mapbox-gl/dist/mapbox-gl.css";

import { useState, useEffect, useMemo, useCallback, useRef } from "react";

import { H } from "@highlight-run/next/client";

import {
  Map,
  GeolocateControl,
  Marker,
  Layer,
  Source,
  NavigationControl,
  Popup,
} from "react-map-gl";
import type {
  LngLatBoundsLike,
  MapRef,
  GeoJSONSource,
  MapMouseEvent,
  MapTouchEvent,
} from "react-map-gl";

import type { Station } from "@/types";

import { useAppState } from "@/state";
import { useStations } from "@/api";

import { useMediaQuery } from "@/hooks/use-media-query";
import { useStationsInRadius } from "@/hooks/use-stations-in-radius";
import { useStationsBetween } from "@/hooks/use-stations-between";

import { formatPrice } from "@/lib/formatters";
import { trackEvent } from "@/lib/analytics";

import PinIcon from "@/components/icons/pin";
import FlagIcon from "@/components/icons/flag";

import SearchBar from "@/components/map/search-bar";
import {
  stationsLayer,
  stationsClusterLayer,
  stationsClusterCountLayer,
  stationsInRadiusCircleLayer,
  routeLayer,
  routeSearchAreaLayer,
} from "@/components/map/layers";

// https://github.com/sandstrom/country-bounding-boxes/blob/master/bounding-boxes.json#L117C25-L117C50
const NL_BOUNDING_BOX = [3.31, 50.8, 7.09, 53.51];

const MAPBOX_STYLE = "mapbox://styles/maher4ever/cm3eanejg003r01qpcfrkd3jf";

function stationsToFeatureCollection(
  stations?: Station[] | { station: Station }[],
) {
  return {
    type: "FeatureCollection",
    features: (stations ?? []).map((s) => {
      const station = "station" in s ? s.station : s;

      return {
        type: "Feature",
        properties: {
          id: station.id,
          euro95_below_average: station.price?.euro95_below_average,
          // Mapbox serializes nested objects of properties automatically. This makes it hard later to convert
          // back to the original object because you need to know which nested objects it had.
          // Hence, we store the whole station as a nested object so its gets fully serialized and we can deserialize it later.
          station: station,
        },
        geometry: {
          type: "Point",
          coordinates: [station.longitude, station.latitude],
        },
      };
    }),
  };
}

interface StationsMapProps {
  className?: string;
  children?: React.ReactNode;
}

function StationsMap({ className, children }: StationsMapProps) {
  const geoControlRef = useRef<mapboxgl.GeolocateControl>(null);

  const [map, setMap] = useState<MapRef | null>(null);
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [cursor, setCursor] = useState<string | undefined>(undefined);
  const [hoveredStation, setHoveredStation] = useState<Station | null>(null);

  const isDesktop = useMediaQuery("(min-width: 768px)");

  const { data: stations = [] } = useStations();
  const {
    selectedLocation,
    selectedStation,
    departureLocation,
    destinationLocation,
    setSelectedLocation,
    setSelectedStation,
    setDepartureLocation,
    setDestinationLocation,
  } = useAppState();

  const { stationsAlongRoute, route, routeBounds, routeSearchArea } =
    useStationsBetween(departureLocation, destinationLocation);

  const { stationsInRadius, stationsInRadiusArea, stationsInRadiusBounds } =
    useStationsInRadius();

  const onLoad = useCallback(() => {
    // Disable one finger zoom
    // See https://stackoverflow.com/a/75774164
    map?.getMap().touchZoomRotate._tapDragZoom.disable();

    // Prevent the geolocate control from changing the zoom level
    // See https://stackoverflow.com/a/77638163
    if (geoControlRef.current) {
      geoControlRef.current._updateCamera = () => {};
    }

    setIsMapLoaded(true);
  }, [map]);

  // Snapshot the canvas to Highlight.io when the map is done rendering
  const onIdle = useCallback(() => {
    const canvas = map?.getCanvas();
    if (canvas) {
      H.snapshot(canvas);
    }
  }, [map]);

  const onClick = useCallback(
    (event: MapMouseEvent) => {
      if (!event.features || event.features.length === 0) {
        if (selectedStation) {
          setSelectedStation(null, { history: "replace" });
        }

        return;
      }

      const feature = event.features[0];
      if (feature.layer?.id === stationsClusterLayer.id) {
        const clusterId = feature.properties?.cluster_id;

        const mapboxSource = map?.getSource("stations") as GeoJSONSource;

        mapboxSource.getClusterExpansionZoom(clusterId, (err, zoom) => {
          if (err) {
            return;
          }

          map?.easeTo({
            center:
              feature.geometry.type === "Point"
                ? (feature.geometry.coordinates as [number, number])
                : undefined,
            zoom: zoom ?? undefined,
            duration: 500,
          });
        });
      } else if (feature.layer?.id === stationsLayer.id) {
        const station = JSON.parse(feature.properties?.station) as Station;

        trackEvent("select_station", {
          station_id: station.id,
          station_name: station.name,
        });

        setSelectedStation(station);
      }
    },
    [selectedStation, setSelectedStation, map],
  );

  const onMouseEnter = useCallback((event: MapMouseEvent | MapTouchEvent) => {
    setCursor("pointer");

    if (!event.features || event.features.length === 0) {
      return;
    }

    const feature = event.features[0];
    if (feature.layer?.id === stationsLayer.id) {
      setHoveredStation(JSON.parse(feature.properties?.station) as Station);
    }
  }, []);

  const onMouseLeave = useCallback(() => {
    setCursor(undefined);
    setHoveredStation(null);
  }, []);

  // Cache the stations feature collection as it doesn't change due to interactions
  const stationsFeatureCollection = useMemo(
    () => stationsToFeatureCollection(stations),
    [stations],
  );

  const stationsSource = useMemo(() => {
    if (stationsAlongRoute) {
      return stationsToFeatureCollection(stationsAlongRoute);
    }

    if (stationsInRadius) {
      return stationsToFeatureCollection(stationsInRadius);
    }

    return stationsFeatureCollection;
  }, [stationsFeatureCollection, stationsInRadius, stationsAlongRoute]);

  useEffect(() => {
    // Load the gas station SDF image
    map?.loadImage("/images/gas-station-sdf.png", (error, image) => {
      if (error) {
        console.error("Error loading image", error);
        return;
      }

      if (!map?.hasImage("gas-station-sdf")) {
        map?.addImage("gas-station-sdf", image!, { sdf: true });
      }
    });
  }, [map]);

  useEffect(() => {
    if (!isMapLoaded) {
      return;
    }

    const flyToConfig =
      departureLocation && !destinationLocation
        ? { location: departureLocation, zoom: 12 }
        : destinationLocation && !departureLocation
          ? { location: destinationLocation, zoom: 12 }
          : selectedStation
            ? { location: selectedStation, zoom: 16 }
            : null;

    // Fly to the selected location
    if (flyToConfig) {
      map?.flyTo({
        center: [flyToConfig.location.longitude, flyToConfig.location.latitude],
        zoom: flyToConfig.zoom,
      });
    }
  }, [
    isMapLoaded,
    departureLocation,
    destinationLocation,
    selectedStation,
    map,
  ]);

  useEffect(() => {
    const { bounds, padding } = routeBounds
      ? {
          bounds: routeBounds,
          padding: isDesktop
            ? { top: 175, bottom: 75, left: 75, right: 75 }
            : { top: 125, bottom: 40, left: 60, right: 60 },
        }
      : {
          bounds: stationsInRadiusBounds,
          padding: isDesktop
            ? 60
            : { top: 70, bottom: 10, left: 10, right: 10 },
        };

    if (isMapLoaded && bounds && !selectedStation) {
      map?.fitBounds(bounds as LngLatBoundsLike, {
        padding,
      });
    }
  }, [
    isMapLoaded,
    stationsInRadiusBounds,
    routeBounds,
    selectedStation,
    isDesktop,
    map,
  ]);

  return (
    <div className={className}>
      <Map
        // @ts-expect-error (TS2322)
        // For more info, see https://github.com/visgl/react-map-gl/issues/2434
        language="nl"
        initialViewState={{
          bounds: NL_BOUNDING_BOX as LngLatBoundsLike,
        }}
        mapStyle={MAPBOX_STYLE} // TODO: change to production style
        mapboxAccessToken={process.env.NEXT_PUBLIC_MAPBOX_ACCESS_TOKEN}
        interactiveLayerIds={[stationsClusterLayer.id!, stationsLayer.id!]}
        onLoad={onLoad}
        onIdle={onIdle}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onTouchStart={onMouseEnter}
        onTouchEnd={onMouseLeave}
        cursor={cursor}
        attributionControl={false}
        touchPitch={false}
        ref={setMap}
        reuseMaps
      >
        <SearchBar
          mapboxAccessToken={process.env.NEXT_PUBLIC_MAPBOX_ACCESS_TOKEN!}
          location={selectedLocation}
          departureLocation={departureLocation}
          destinationLocation={destinationLocation}
          setLocation={setSelectedLocation}
          setDepartureLocation={setDepartureLocation}
          setDestinationLocation={setDestinationLocation}
        />
        <NavigationControl
          position="bottom-right"
          visualizePitch={false}
          showCompass={false}
        />
        <GeolocateControl
          ref={geoControlRef}
          position="bottom-right"
          showUserLocation={false}
          positionOptions={{ enableHighAccuracy: true }}
          onGeolocate={({ coords }) => {
            setSelectedLocation({
              name: "Huidige locatie",
              longitude: coords.longitude,
              latitude: coords.latitude,
            });
          }}
        />
        {departureLocation && (
          <Marker
            longitude={departureLocation.longitude}
            latitude={departureLocation.latitude}
            anchor="bottom"
          >
            <PinIcon className="stroke-white stroke-1" />
          </Marker>
        )}
        {destinationLocation && (
          <Marker
            longitude={destinationLocation.longitude}
            latitude={destinationLocation.latitude}
            anchor="center"
          >
            <FlagIcon />
          </Marker>
        )}
        {selectedLocation && (
          <Marker
            longitude={selectedLocation.longitude}
            latitude={selectedLocation.latitude}
            anchor="bottom"
          >
            <PinIcon className="stroke-white stroke-1" pulse />
          </Marker>
        )}
        {hoveredStation && hoveredStation.id !== selectedStation?.id && (
          <Popup
            longitude={hoveredStation.longitude}
            latitude={hoveredStation.latitude}
            anchor="bottom"
            closeButton={false}
            className="hover-popup"
          >
            {hoveredStation.name}{" "}
            {hoveredStation.price?.euro95 &&
              formatPrice(hoveredStation.price.euro95, true)}
          </Popup>
        )}
        {selectedStation && (
          <Popup
            longitude={selectedStation.longitude}
            latitude={selectedStation.latitude}
            anchor="bottom"
            closeButton={false}
            closeOnClick={false}
            closeOnMove={false}
          >
            {selectedStation.name}{" "}
            {selectedStation.price?.euro95 &&
              formatPrice(selectedStation.price.euro95, true)}
          </Popup>
        )}
        <Source
          id="stations-in-radius-area"
          type="geojson"
          data={stationsInRadiusArea}
        >
          <Layer {...stationsInRadiusCircleLayer} />
        </Source>
        <Source
          id="route-search-area"
          type="geojson"
          data={routeSearchArea || { type: "Feature" }}
        >
          <Layer {...routeSearchAreaLayer} />
        </Source>
        <Source id="route" type="geojson" data={route || { type: "Feature" }}>
          <Layer {...routeLayer} />
        </Source>
        <Source
          id="stations"
          type="geojson"
          data={stationsSource}
          cluster={true}
          clusterRadius={60}
          clusterMaxZoom={12}
        >
          <Layer {...stationsClusterLayer} />
          <Layer {...stationsClusterCountLayer} />
          <Layer {...stationsLayer} />
        </Source>
      </Map>
      {children}
    </div>
  );
}

export default StationsMap;
