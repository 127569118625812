import { useQuery } from "@tanstack/react-query";

import { useAppState } from "@/state";

import { GasPriceSchema, StationDetailsSchema, StationsSchema } from "@/types";
import type { GasPrice, StationDetails, Stations } from "@/types";

const API_URL =
  process.env.NODE_ENV === "production"
    ? "https://api.pompwijzer.nl/api"
    : "/api";

export function useStations() {
  return useQuery<Stations>({
    queryKey: ["stations"],
    queryFn: async () => {
      const response = await fetch(`${API_URL}/stations`);
      return StationsSchema.parse(await response.json());
    },
  });
}

export function useSelectedStationDetails() {
  const { selectedStation } = useAppState();
  const stationId = selectedStation?.id;

  return useQuery<StationDetails>({
    queryKey: ["stations", stationId],
    queryFn: async () => {
      const response = await fetch(`${API_URL}/stations/${stationId}`);
      return StationDetailsSchema.parse(await response.json());
    },
    enabled: !!stationId,
  });
}

export function useStationsAveragePrice() {
  return useQuery<GasPrice>({
    queryKey: ["prices", "average"],
    queryFn: async () => {
      const response = await fetch(`${API_URL}/prices/average`);
      return GasPriceSchema.parse(await response.json());
    },
  });
}
