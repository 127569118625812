import { memo } from "react";

type RouteIconProps = {
  className?: string;
};

function RouteIcon({ className }: RouteIconProps) {
  return (
    <svg viewBox="0 -960 960 960" className={className}>
      <path d="M320-360h66.67v-126.67H556v90L680-520 556-644v90.67H353.33q-14.33 0-23.83 9.5-9.5 9.5-9.5 23.83v160ZM480-78q-13 0-25.5-4.67-12.5-4.66-21.83-14l-336-336q-9.34-9.33-14-21.83Q78-467 78-480t4.67-25.5q4.66-12.5 14-21.83l336-336q9.33-9.34 21.83-14Q467-882 480-882t25.5 4.67q12.5 4.66 21.83 14l336 336q9.34 9.33 14 21.83Q882-493 882-480t-4.67 25.5q-4.66 12.5-14 21.83l-336 336q-9.33 9.34-21.83 14Q493-78 480-78Z" />
    </svg>
  );
}

export default memo(RouteIcon);
