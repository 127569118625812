import { useMemo, useCallback } from "react";

import { ChevronDown, LoaderCircle, X } from "lucide-react";

import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

import { cn } from "@/lib/utils";
import { formatDistance, formatPrice } from "@/lib/formatters";

import { useAppState } from "@/state";
import type { Location, Station } from "@/types";
import { SEARCH_RADIUSES_KM } from "@/consts";

import { useStationsInRadius } from "@/hooks/use-stations-in-radius";
import { trackEvent } from "@/lib/analytics";

type LocationDetailsProps = {
  location: Location;
  className?: string;
};

function LocationDetails({ location, className }: LocationDetailsProps) {
  const { searchRadius, setSearchRadius } = useAppState();
  const { stationsInRadius = [], isLoading } = useStationsInRadius();
  const { setSelectedStation, setSelectedLocation } = useAppState();

  const sortedStations = useMemo(
    () =>
      stationsInRadius.sort(
        (a, b) =>
          // Sort by price, then by distance
          (a.station.price?.euro95 ?? Infinity) -
            (b.station.price?.euro95 ?? Infinity) || a.distance - b.distance,
      ),
    [stationsInRadius],
  );

  const handleStationSelect = useCallback(
    (station: Station) => {
      trackEvent("select_station", {
        station_id: station.id,
        station_name: station.name,
        from_location: location.name,
      });

      setSelectedStation(station);
    },
    [location.name, setSelectedStation],
  );

  return (
    <div className={cn("flex flex-col", className)}>
      <div className="flex items-end justify-between">
        <div className="text-lg font-semibold tracking-tight">Tankstations</div>
        <Button
          variant="ghost"
          size="icon"
          className="relative -right-2 md:right-0 [&_svg]:size-6"
          onClick={() => setSelectedLocation(null, { history: "replace" })}
        >
          <X className="text-muted-foreground" />
        </Button>
      </div>
      <div className="mt-2 text-muted-foreground">
        Binnen{" "}
        <DropdownMenu>
          <DropdownMenuTrigger className="inline-flex items-center gap-1 rounded-lg border bg-secondary px-1 text-secondary-foreground">
            {searchRadius} km <ChevronDown className="size-4" />
          </DropdownMenuTrigger>
          <DropdownMenuContent align="start">
            <DropdownMenuLabel>Straal</DropdownMenuLabel>
            <DropdownMenuSeparator />
            {SEARCH_RADIUSES_KM.map((radius) => (
              <DropdownMenuCheckboxItem
                key={radius}
                checked={radius === searchRadius}
                onClick={() => setSearchRadius(radius)}
              >
                {radius} km
              </DropdownMenuCheckboxItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>{" "}
        van {location.name}
      </div>
      <div className="mt-4 flex flex-col gap-2">
        {isLoading ? (
          <div className="flex items-center justify-center p-8">
            <LoaderCircle className="h-6 w-6 animate-spin" />
          </div>
        ) : (
          sortedStations.map(({ station, distance }, i) => (
            <Button
              key={station.id}
              variant="ghost"
              size="lg"
              className="pl-2 pr-4"
              onClick={() => handleStationSelect(station)}
            >
              <div className="flex w-full items-center gap-2">
                <div className="min-w-[1.4rem] place-self-start text-right text-muted-foreground">
                  {i + 1}.
                </div>
                <div className="flex flex-1 flex-wrap justify-start leading-tight">
                  <span className="mr-2">{station.name}</span>
                  <span className="text-muted-foreground">
                    {formatDistance(distance)}
                  </span>
                </div>
                {station.price?.euro95 && (
                  <div
                    className={`text-sm ${
                      station.price.euro95_below_average
                        ? "text-emerald-500"
                        : "text-rose-500"
                    }`}
                  >
                    {formatPrice(station.price.euro95 ?? 0, true)}
                  </div>
                )}
              </div>
            </Button>
          ))
        )}
      </div>
    </div>
  );
}

export default LocationDetails;
