import { memo } from "react";

type MapSearchIconProps = {
  className?: string;
};

function MapSearchIcon({ className }: MapSearchIconProps) {
  return (
    <svg viewBox="0 -960 960 960" className={className}>
      <path d="M171.33-138.67q-18 8.67-34.66-2.16Q120-151.67 120-172v-558.67q0-13 7.5-23t19.83-15L352.67-840 608-750.67 788.67-822q18-8 34.66 2.5Q840-809 840-788.67v371q-30.33-53-81.5-81.66Q707.33-528 649.33-528q-19.33 0-38.66 3.17Q591.33-521.67 572-514v-181.33L388-758v534.33l-216.67 85ZM836-80 734.67-180.67q-18.67 13-39.84 19.5-21.16 6.5-45.5 6.5-63.33 0-108.33-45T496-308q0-64 45-108.67 45-44.66 108.33-44.66 64 0 108.67 44.66Q802.67-372 802.67-308q0 23-6.17 43.83-6.17 20.84-17.83 38.84L880-124l-44 44ZM649.33-221.33q36.67 0 61.5-24 24.84-24 25.17-62.67.33-36.67-24.83-61.67-25.17-25-61.84-25-36.66 0-61.66 25t-25 61.67q0 36.67 25 61.67t61.66 25Z" />
    </svg>
  );
}

export default memo(MapSearchIcon);
