import { memo, useState, useCallback, useMemo, useEffect } from "react";

import { Search, EllipsisVertical, ArrowUpDown } from "lucide-react";

import { Location } from "@/types";

import { Button } from "@/components/ui/button";
import { Separator } from "@/components/ui/separator";
import { AutoComplete, AutoCompleteInput } from "@/components/map/autocomplete";

import PinIcon from "@/components/icons/pin";
import FlagIcon from "@/components/icons/flag";
import RouteIcon from "@/components/icons/material-symbols/route";
import MapSearchIcon from "@/components/icons/material-symbols/map-search";

import { useMapGeocoding } from "@/hooks/use-map-geocoding";
import { trackEvent } from "@/lib/analytics";

type SearchModeProps = {
  mapboxAccessToken: string;
  searchValue: string;
  setSearchValue: (value: string) => void;
  setLocation: (location: Location) => void;
  onRouteModeClick: () => void;
};

function SearchMode({
  mapboxAccessToken,
  searchValue,
  setSearchValue,
  onRouteModeClick,
  setLocation,
}: SearchModeProps) {
  const [autoCompleteOpen, setAutoCompleteOpen] = useState(false);

  const { data: searchResults = [], isLoading } = useMapGeocoding(
    mapboxAccessToken,
    searchValue,
    autoCompleteOpen,
  );

  const handleLocationSelect = useCallback(
    (location: Location) => {
      trackEvent("select_search_location", {
        location_name: location.name,
      });
      setLocation(location);
    },
    [setLocation],
  );

  return (
    <AutoComplete
      open={autoCompleteOpen}
      setOpen={setAutoCompleteOpen}
      onValueChanged={setSearchValue}
      onLocationSelected={handleLocationSelect}
      items={searchResults}
      isLoading={isLoading}
    >
      <div className="overflow-hidden rounded-lg bg-white shadow-[0_0_0_2px_rgba(0,0,0,0.1)]">
        <div className="flex h-12 items-center justify-between md:h-10">
          <Search className="mx-4 size-6 max-w-4 stroke-primary-foreground md:mx-3" />
          <AutoCompleteInput
            searchValue={searchValue}
            onSearchValueChange={setSearchValue}
            autoCompleteOpen={autoCompleteOpen}
            setAutoCompleteOpen={setAutoCompleteOpen}
          />
          <Button
            variant="ghost"
            size="sm"
            onClick={onRouteModeClick}
            className="h-full rounded-l-none fill-cyan-600 hover:bg-gray-100 md:px-2 [&_svg]:size-6"
          >
            <RouteIcon />
          </Button>
        </div>
      </div>
    </AutoComplete>
  );
}

type RouteModeProps = {
  mapboxAccessToken: string;
  departureValue: string;
  destinationValue: string;
  departureLocation: Location | null;
  destinationLocation: Location | null;
  setDepartureValue: (value: string) => void;
  setDestinationValue: (value: string) => void;
  setDepartureLocation: (location: Location | null) => void;
  setDestinationLocation: (location: Location | null) => void;
  onSearchModeClick: () => void;
};

function RouteMode({
  mapboxAccessToken,
  departureValue,
  destinationValue,
  departureLocation,
  destinationLocation,
  setDepartureValue,
  setDestinationValue,
  setDepartureLocation,
  setDestinationLocation,
  onSearchModeClick,
}: RouteModeProps) {
  const [activeInput, setActiveInput] = useState<
    "departure" | "destination" | null
  >(null);
  const [autoCompleteOpen, setAutoCompleteOpen] = useState(false);

  const activeValue = useMemo(
    () => (activeInput === "departure" ? departureValue : destinationValue),
    [activeInput, departureValue, destinationValue],
  );

  const setActiveValue = useCallback(
    (value: string) => {
      if (activeInput === "departure") {
        setDepartureValue(value);
      } else {
        setDestinationValue(value);
      }
    },
    [activeInput, setDepartureValue, setDestinationValue],
  );

  const onReverseInputs = useCallback(() => {
    trackEvent("reverse_route");

    setDepartureValue(destinationValue);
    setDestinationValue(departureValue);
    setDepartureLocation(destinationLocation);
    setDestinationLocation(departureLocation);
  }, [
    departureValue,
    destinationValue,
    setDepartureValue,
    setDestinationValue,
    departureLocation,
    destinationLocation,
    setDepartureLocation,
    setDestinationLocation,
  ]);

  const { data: searchResults = [], isLoading } = useMapGeocoding(
    mapboxAccessToken,
    activeValue,
    autoCompleteOpen,
  );

  const handleLocationSet = useCallback(
    (location: Location) => {
      const locationType =
        activeInput === "departure" ? "departure" : "destination";

      trackEvent("select_route_location", {
        location_type: locationType,
        location_name: location.name,
      });

      if (activeInput === "departure") {
        setDepartureLocation(location);
      } else {
        setDestinationLocation(location);
      }
    },
    [activeInput, setDepartureLocation, setDestinationLocation],
  );

  return (
    <AutoComplete
      open={autoCompleteOpen}
      setOpen={setAutoCompleteOpen}
      onValueChanged={setActiveValue}
      onLocationSelected={handleLocationSet}
      items={searchResults}
      isLoading={isLoading}
      className="rounded-xl"
    >
      <div className="flex overflow-hidden rounded-xl bg-white shadow-[0_0_0_2px_rgba(0,0,0,0.1)]">
        <div className="mx-4 flex max-w-4 flex-col items-center justify-center gap-2">
          <PinIcon className="size-4" />
          <EllipsisVertical className="size-4 stroke-muted-foreground" />
          <FlagIcon className="size-4" />
        </div>
        <div className="flex flex-1 flex-col justify-center gap-2 py-2">
          <div className="flex h-8 items-center justify-between">
            <AutoCompleteInput
              searchValue={departureValue}
              onSearchValueChange={setDepartureValue}
              onSelected={() => setActiveInput("departure")}
              autoCompleteOpen={autoCompleteOpen}
              setAutoCompleteOpen={setAutoCompleteOpen}
              placeholder="Vertrekpunt..."
            />
          </div>
          <Separator
            orientation="horizontal"
            className="bg-muted-foreground/30"
          />
          <div className="flex h-8 items-center justify-between">
            <AutoCompleteInput
              searchValue={destinationValue}
              onSearchValueChange={setDestinationValue}
              onSelected={() => setActiveInput("destination")}
              autoCompleteOpen={autoCompleteOpen}
              setAutoCompleteOpen={setAutoCompleteOpen}
              placeholder="Bestemming..."
            />
          </div>
        </div>
        <div className="flex w-12 flex-col justify-evenly">
          <Button
            variant="ghost"
            size="sm"
            onClick={onSearchModeClick}
            className="flex w-full flex-1 items-center justify-center rounded-l-none hover:bg-gray-100 [&_svg]:size-6"
          >
            <MapSearchIcon className="fill-cyan-600" />
          </Button>
          <Button
            variant="ghost"
            size="sm"
            className="flex w-full flex-1 items-center justify-center rounded-l-none hover:bg-transparent [&_svg]:size-4"
            onClick={onReverseInputs}
          >
            <ArrowUpDown className="stroke-primary-foreground" />
          </Button>
        </div>
      </div>
    </AutoComplete>
  );
}

type SearchBarProps = {
  mapboxAccessToken: string;
  location: Location | null;
  departureLocation: Location | null;
  destinationLocation: Location | null;
  setLocation: (location: Location | null) => void;
  setDepartureLocation: (location: Location | null) => void;
  setDestinationLocation: (location: Location | null) => void;
};

function SearchBar({
  mapboxAccessToken,
  location = null,
  departureLocation = null,
  destinationLocation = null,
  setLocation,
  setDepartureLocation,
  setDestinationLocation,
}: SearchBarProps) {
  const [searchValue, setSearchValue] = useState(location?.name ?? "");
  const [departureValue, setDepartureValue] = useState(
    departureLocation?.name ?? "",
  );
  const [destinationValue, setDestinationValue] = useState(
    destinationLocation?.name ?? "",
  );

  const [isRouteMode, setIsRouteMode] = useState(
    !!departureLocation || !!destinationLocation,
  );

  useEffect(() => {
    setSearchValue(location?.name ?? "");
    setDepartureValue(departureLocation?.name ?? "");
    setDestinationValue(destinationLocation?.name ?? "");

    if (location) {
      setIsRouteMode(false);
    } else if (departureLocation || destinationLocation) {
      setIsRouteMode(true);
    }
  }, [location, departureLocation, destinationLocation]);

  return (
    <div className="absolute left-0 top-0 z-10 w-full p-[10px] font-mapbox text-base text-primary-foreground md:w-96">
      {isRouteMode ? (
        <RouteMode
          mapboxAccessToken={mapboxAccessToken}
          departureValue={departureValue}
          destinationValue={destinationValue}
          departureLocation={departureLocation}
          destinationLocation={destinationLocation}
          setDepartureValue={setDepartureValue}
          setDestinationValue={setDestinationValue}
          onSearchModeClick={() => {
            trackEvent("switch_search_bar_mode", {
              search_bar_mode: "search",
            });

            setIsRouteMode(false);
          }}
          setDepartureLocation={setDepartureLocation}
          setDestinationLocation={setDestinationLocation}
        />
      ) : (
        <SearchMode
          mapboxAccessToken={mapboxAccessToken}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          onRouteModeClick={() => {
            trackEvent("switch_search_bar_mode", {
              search_bar_mode: "route",
            });

            setIsRouteMode(true);
          }}
          setLocation={setLocation}
        />
      )}
    </div>
  );
}

export default memo(SearchBar);
