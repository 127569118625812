import { memo } from "react";

import { cn } from "@/lib/utils";

type FlagIconProps = {
  className?: string;
};

function FlagIcon({ className }: FlagIconProps) {
  return (
    <svg viewBox="0 0 16 16" className={cn("size-5 fill-cyan-600", className)}>
      <path d="M1 1V16H3V10H7L9 12H15V3H9L7 1H1Z" />
    </svg>
  );
}

export default memo(FlagIcon);
