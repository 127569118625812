"use client";

import { useMemo } from "react";

import {
  useQueryState,
  parseAsJson,
  parseAsNumberLiteral,
  Options,
  parseAsInteger,
} from "nuqs";

import { LocationSchema, StationSchema } from "@/types";
import {
  DEFAULT_SEARCH_DISTANCE_KM,
  DEFAULT_SEARCH_RADIUS_KM,
  SEARCH_DISTANCES_KM,
  SEARCH_RADIUSES_KM,
} from "@/consts";

function createExclusiveSetter<
  S extends (...args: any) => any, // eslint-disable-line @typescript-eslint/no-explicit-any
  C extends ((args: null, opts: Options) => any)[], // eslint-disable-line @typescript-eslint/no-explicit-any
>(setter: S, clearingSetters: C) {
  return (...params: Parameters<S>) => {
    clearingSetters.forEach((s) => s(null, { history: "replace" }));
    setter(...params);
  };
}

function useSelectedLocation() {
  return useQueryState(
    "location",
    parseAsJson(LocationSchema.parse).withOptions({ history: "push" }),
  );
}

function useDepartureLocation() {
  return useQueryState(
    "departure",
    parseAsJson(LocationSchema.parse).withOptions({ history: "push" }),
  );
}

function useDestinationLocation() {
  return useQueryState(
    "destination",
    parseAsJson(LocationSchema.parse).withOptions({ history: "push" }),
  );
}

function useSelectedStation() {
  return useQueryState(
    "stationId",
    parseAsJson(StationSchema.parse).withOptions({ history: "push" }),
  );
}

function useSearchRadius() {
  return useQueryState(
    "searchRadius",
    parseAsNumberLiteral(SEARCH_RADIUSES_KM).withDefault(
      DEFAULT_SEARCH_RADIUS_KM,
    ),
  );
}

function useSearchDistance() {
  return useQueryState(
    "searchDistance",
    parseAsNumberLiteral(SEARCH_DISTANCES_KM).withDefault(
      DEFAULT_SEARCH_DISTANCE_KM,
    ),
  );
}

function useRouteId() {
  return useQueryState("routeId", parseAsInteger.withDefault(0));
}

export function useAppState() {
  const [selectedLocation, changeSelectedLocation] = useSelectedLocation();
  const [departureLocation, changeDepartureLocation] = useDepartureLocation();
  const [destinationLocation, changeDestinationLocation] =
    useDestinationLocation();
  const [selectedStation, setSelectedStation] = useSelectedStation();
  const [searchRadius, setSearchRadius] = useSearchRadius();
  const [searchDistance, setSearchDistance] = useSearchDistance();
  const [routeId, setRouteId] = useRouteId();

  // Selecting a location is mutually exclusive with selecting a station or a departure/destination.
  const setSelectedLocation = useMemo(
    () =>
      createExclusiveSetter(changeSelectedLocation, [
        setSelectedStation,
        changeDepartureLocation,
        changeDestinationLocation,
      ]),
    [
      changeSelectedLocation,
      setSelectedStation,
      changeDepartureLocation,
      changeDestinationLocation,
    ],
  );

  // Selecting a departure location is mutually exclusive with selecting a station or a location.
  // Also, we clear the route ID to automatically select the first route.
  const setDepartureLocation = useMemo(
    () =>
      createExclusiveSetter(changeDepartureLocation, [
        setSelectedStation,
        changeSelectedLocation,
        setRouteId,
      ]),
    [
      changeDepartureLocation,
      setSelectedStation,
      changeSelectedLocation,
      setRouteId,
    ],
  );

  // Selecting a destination location is mutually exclusive with selecting a station or a location.
  // Also, we clear the route ID to automatically select the first route.
  const setDestinationLocation = useMemo(
    () =>
      createExclusiveSetter(changeDestinationLocation, [
        setSelectedStation,
        changeSelectedLocation,
        setRouteId,
      ]),
    [
      changeDestinationLocation,
      setSelectedStation,
      changeSelectedLocation,
      setRouteId,
    ],
  );

  return useMemo(
    () => ({
      selectedLocation,
      departureLocation,
      destinationLocation,
      selectedStation,
      searchRadius,
      searchDistance,
      routeId,
      setSelectedLocation,
      setDepartureLocation,
      setDestinationLocation,
      setSelectedStation,
      setSearchRadius,
      setSearchDistance,
      setRouteId,
    }),
    [
      selectedLocation,
      departureLocation,
      destinationLocation,
      selectedStation,
      searchRadius,
      searchDistance,
      routeId,
      setSelectedLocation,
      setDepartureLocation,
      setDestinationLocation,
      setSelectedStation,
      setSearchRadius,
      setSearchDistance,
      setRouteId,
    ],
  );
}
