import { z } from "zod";

export const GasPriceWithStatsSchema = z.object({
  euro95: z.number().optional(),
  euro95_below_average: z.boolean().optional(),
  timestamp: z.string().datetime().pipe(z.coerce.date()),
});
export type GasPriceWithStats = z.infer<typeof GasPriceWithStatsSchema>;

export const StationSchema = z.object({
  id: z.number(),
  name: z.string(),
  address: z.string(),
  postal_code: z.string(),
  city: z.string(),
  country: z.string(),
  longitude: z.number(),
  latitude: z.number(),
  price: GasPriceWithStatsSchema.optional(),
});
export type Station = z.infer<typeof StationSchema>;

export const StationsSchema = z.array(StationSchema);
export type Stations = z.infer<typeof StationsSchema>;

export const GasPriceSchema = z.object({
  euro95: z.number().optional(),
  timestamp: z.string().datetime().pipe(z.coerce.date()),
});
export type GasPrice = z.infer<typeof GasPriceSchema>;

export const GasPricesSchema = z.array(GasPriceSchema);
export type GasPrices = z.infer<typeof GasPricesSchema>;

export const StationDetailsSchema = z.object({
  id: z.number(),
  name: z.string(),
  address: z.string(),
  postal_code: z.string(),
  city: z.string(),
  country: z.string(),
  longitude: z.number(),
  latitude: z.number(),
  prices_24_hours: GasPricesSchema,
  prices_30_days: GasPricesSchema,
});
export type StationDetails = z.infer<typeof StationDetailsSchema>;

export const CoordinatesSchema = z.object({
  longitude: z.number(),
  latitude: z.number(),
});
export type Coordinates = z.infer<typeof CoordinatesSchema>;

export const LocationSchema = z.object({
  name: z.string(),
  longitude: z.number(),
  latitude: z.number(),
});
export type Location = z.infer<typeof LocationSchema>;
