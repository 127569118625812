import { useMemo } from "react";

import { useQuery } from "@tanstack/react-query";

import { z } from "zod";

import { bbox, booleanPointInPolygon, buffer, distance } from "@turf/turf";

import type { Location } from "@/types";
import { useStations } from "@/api";
import { useAppState } from "@/state";

const MAPBOX_DIRECTIONS_API_URL =
  "https://api.mapbox.com/directions/v5/mapbox/driving-traffic";

const DirectionsResultSchema = z.object({
  code: z.literal("Ok"),
  routes: z.array(
    z.object({
      distance: z.number(),
      duration_typical: z.number(),
      geometry: z.object({
        type: z.literal("LineString"),
        coordinates: z.array(z.array(z.number()).length(2)),
      }),
      legs: z.array(
        z.object({
          summary: z.string(),
        }),
      ),
    }),
  ),
});

export function useStationsBetween(
  departureLocation: Location | null,
  destinationLocation: Location | null,
) {
  const { searchDistance, routeId } = useAppState();
  const { data: stations } = useStations();

  const departureCoordinates =
    departureLocation &&
    `${departureLocation.longitude},${departureLocation.latitude}`;
  const destinationCoordinates =
    destinationLocation &&
    `${destinationLocation.longitude},${destinationLocation.latitude}`;

  const { data: routes } = useQuery({
    queryKey: ["route", departureCoordinates, destinationCoordinates],
    staleTime: 5 * 60 * 1000, // 5 minutes
    queryFn: async () => {
      const params = new URLSearchParams({
        access_token: process.env.NEXT_PUBLIC_MAPBOX_ACCESS_TOKEN!,
        alternatives: "true",
        overview: "full",
        geometries: "geojson",
      });

      const response = await fetch(
        `${MAPBOX_DIRECTIONS_API_URL}/${departureCoordinates};${destinationCoordinates}?${params.toString()}`,
      );

      return DirectionsResultSchema.parse(await response.json());
    },
    select: (data) =>
      data.routes.map((route) => ({
        type: "Feature",
        geometry: route.geometry,
        properties: {
          summary: route.legs[0].summary,
          distance: route.distance,
          duration_typical: route.duration_typical,
        },
      })),
    enabled: !!departureLocation && !!destinationLocation,
  });

  return useMemo(() => {
    const route =
      routes && routes.length > routeId ? routes[routeId] : routes?.[0];
    const routeBounds = route && bbox(route.geometry);
    const routeSearchArea = route && buffer(route.geometry, searchDistance);

    const departurePosition = departureLocation && [
      departureLocation.longitude,
      departureLocation.latitude,
    ];

    // Note: Using booleanPointInPolygon for filtering is more than 50x faster than
    //       calculating the distance through pointToLineDistance and then filtering.
    const stationsAlongRoute =
      route &&
      routeSearchArea &&
      departurePosition &&
      stations
        ?.filter((station) =>
          booleanPointInPolygon(
            [station.longitude, station.latitude],
            routeSearchArea,
          ),
        )
        .map((station) => ({
          station,
          distanceFromDeparture: distance(
            [station.longitude, station.latitude],
            departurePosition,
          ),
        }));

    return {
      allRoutes: routes,
      route,
      routeBounds,
      routeSearchArea,
      stationsAlongRoute,
    };
  }, [searchDistance, stations, routes, departureLocation, routeId]);
}
