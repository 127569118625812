"use client";

import { useMediaQuery } from "@/hooks/use-media-query";

import { ScrollArea } from "@/components/ui/scroll-area";
import Footer from "@/components/footer";

import StationsMap from "@/app/stations-map";
import SideBar from "@/app/sidebar";

export default function Page() {
  const isDesktop = useMediaQuery("(min-width: 768px)");
  const ScrollElement = isDesktop ? ScrollArea : "div";

  return (
    // For why the h-0 is needed, see: https://github.com/w3c/csswg-drafts/issues/6260#issuecomment-837190156
    <div className="flex h-0 grow flex-col-reverse md:flex-row">
      <div className="flex h-0 basis-2/5 md:h-auto md:w-[26rem] md:flex-none">
        <ScrollElement className="m-2 grow">
          <div className="flex h-full grow flex-col justify-between gap-2">
            <SideBar />
            <Footer />
          </div>
        </ScrollElement>
      </div>
      <StationsMap className="flex-1" />
    </div>
  );
}
